import React, { useEffect } from "react";
import { SubLayout, Success } from "../../components";
import Seo from "../../components/Seo";
// import Seo from "../components/seo";

const ContactSuccessPage = () => {
  useEffect(() => {});
  return (
    <SubLayout>
      <Seo
        title="お問い合わせ"
        description="クリニックへのお仕事やご要望、または取材などについてはこちらからお問合せください。"
      />
      <Success />
    </SubLayout>
  );
};

export default ContactSuccessPage;
